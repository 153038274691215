import React, { useEffect, useRef } from "react";

const KotlinPlayground = (props) => {
  const playgroundRef = useRef();

  useEffect(() => {
    if (window.KotlinPlayground) {
      window.KotlinPlayground(playgroundRef.current);
    }
  }, []);

  return (
    <div>
      <div
        ref={playgroundRef}
        className="kotlin-playground"
        data-autocomplete="true"
        data-highlight-on-fly="true"
        data-output-id="output"
        style={{ width: "100%", height: "400px" }}
      >
        {props.koodi}
      </div>
      <div id="output"></div>
    </div>
  );
};

export default KotlinPlayground;
