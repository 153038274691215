import React from "react";
import MarkdownComponent from "../../MarkdownComponent";
import PythonPlot from "./PythonPlot";

function IntroDataAnalysis() {
  return (
    // <div>
    //   <h1>Data Analysis</h1>
    //   <MarkdownComponent path="/DataAnalysis/DataAnalysisIntro.md" />
    //   <PythonPlot />
    // </div>
    <div>
      <p>Click the link below to open the Data Analysis Streamlit app:</p>
      <a
        href="http://86.50.253.179:8502/"
        target="_blank" // Opens in a new tab
        rel="noopener noreferrer" // Security for external links
        style={{ color: "blue", textDecoration: "underline" }} // Optional styling
      >
        Open Streamlit App
      </a>
    </div>
  );
}
export default IntroDataAnalysis;
