import React from "react";
import MarkdownComponent from "../../MarkdownComponent";
import KotlinPlayground from "../../Kotlinplayground";
import KotlinScoreTracker from "../../KotlinScoreTracker";

const koodi1 = `fun main() {
    println("Hei maailma!")
}`;

const koodi2 = `fun main() {
  println("Hei suomi!")
}`;

function Kotlin() {
  return (
    <div>
      <h1>Kotlin</h1>
      {/* <MarkdownComponent path="/RFkurssi/Antennit_md.md" /> */}
      <KotlinPlayground koodi={koodi1} />
      <KotlinPlayground koodi={koodi2} />
      {/* <KotlinScoreTracker /> */}
    </div>
  );
}
export default Kotlin;
